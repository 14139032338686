import { usePage } from '@inertiajs/vue3';
import { hexToRgb } from '@aspect/shared/utils/color.ts';
import { watchEffect } from 'vue';

export function useSite() {
    const globals = computed<any>(() => {
        return usePage().props.globals;
    });

    const navs = computed<any>(() => {
        return usePage().props.navs;
    });

    const fallbackColors = {
        'black': '#00000',
        'dark': '#171717',
        'dark-alt': '#262626',
        'light-alt': '#e5e5e5',
        'light': '#f5f5f5',
        'white': '#ffffff',
        'primary': '#888888',
        'secondary': '#888888',
        'tertiary': '#888888',
        'success': '#22c55e',
        'warning': '#f59e0b',
        'danger': '#ef4444',
    };

    // Font name mapping
    const getFontDisplayName = (fontValue: string) => {
        const fontMap: Record<string, string> = {
            'inter': 'Inter',
            'poppins': 'Poppins',
            'noto': 'Noto Sans',
            'roboto': 'Roboto',
            'open-sans': 'Open Sans',
            'lato': 'Lato',
            'merriweather-sans': 'Merriweather Sans',
            'lexend': 'Lexend',
            'barlow': 'Barlow',
            'helvetica': 'Helvetica',
            'arial': 'Arial',
            'montserrat': 'Montserrat',
            'raleway': 'Raleway',
            'quicksand': 'Quicksand',
            'josefin-sans': 'Josefin Sans',
            'space-grotesk': 'Space Grotesk',
            'bebas-neue': 'Bebas Neue',
            'roboto-serif': 'Roboto Serif',
            'libre-baskerville': 'Libre Baskerville',
            'crimson-pro': 'Crimson Pro',
            'merriweather': 'Merriweather',
            'noto-serif': 'Noto Serif',
            'roboto-slab': 'Roboto Slab',
            'josefin-slab': 'Josefin Slab',
            'zilla-slab': 'Zilla Slab',
            'bitter': 'Bitter',
            'caveat': 'Caveat',
            'caveat-brush': 'Caveat Brush',
            'amatic-sc': 'Amatic SC',
            'lobster': 'Lobster',
            'lobster-two': 'Lobster Two',
            'comic-neue': 'Comic Neue',
            'sriracha': 'Sriracha',
            'monocode': 'Monocode',
            'system': 'system-ui',
            'sans': 'sans-serif',
            'serif': 'serif',
            'mono': 'monospace',
        };

        return fontMap[fontValue] || fontValue;
    };

    // Set CSS variables for tenant fonts
    watchEffect(() => {
        if (globals.value?.fonts) {
            const style = document.createElement('style');
            const cssVars = Object.entries({
                'font-title': getFontDisplayName(globals.value.fonts?.title_font_family?.value || 'Inter'),
                'font-subtitle': getFontDisplayName(globals.value.fonts?.subtitle_font_family?.value || 'Inter'),
                'font-content': getFontDisplayName(globals.value.fonts?.content_font_family?.value || 'Inter'),
                'font-label': getFontDisplayName(globals.value.fonts?.label_font_family?.value || 'Inter'),
            }).map(([key, value]) => `--tenant-${key}: ${value}`).join(';\n');

            style.textContent = `:root {${cssVars}}`;

            // Remove any previous style element we added
            const prevStyle = document.getElementById('tenant-fonts');
            if (prevStyle) {
                prevStyle.remove();
            }

            style.id = 'tenant-fonts';
            document.head.appendChild(style);
        }
    });

    // Set CSS variables for tenant colors
    watchEffect(() => {
        if (globals.value?.colors) {
            const style = document.createElement('style');
            const cssVars = Object.entries({
                'black': globals.value.colors.black || fallbackColors.black,
                'dark': globals.value.colors.dark || fallbackColors.dark,
                'dark-alt': globals.value.colors.dark_alternate || fallbackColors['dark-alt'],
                'light-alt': globals.value.colors.light_alternate || fallbackColors['light-alt'],
                'light': globals.value.colors.light || fallbackColors.light,
                'white': globals.value.colors.white || fallbackColors.white,
                'primary': globals.value.colors.primary || fallbackColors.primary,
                'secondary': globals.value.colors.secondary || fallbackColors.secondary,
                'tertiary': globals.value.colors.tertiary || fallbackColors.tertiary,
                'success': globals.value.colors.success || fallbackColors.success,
                'warning': globals.value.colors.warning || fallbackColors.warning,
                'danger': globals.value.colors.danger || fallbackColors.danger,
            }).map(([key, value]) => {
                const rgb = hexToRgb(value);
                return [
                    `--tenant-${key}: ${value}`,
                    `--tenant-${key}-rgb: ${rgb.r}, ${rgb.g}, ${rgb.b}`,
                ];
            }).flat().join(';\n');

            style.textContent = `:root {${cssVars}}`;

            // Remove any previous style element we added
            const prevStyle = document.getElementById('tenant-colors');
            if (prevStyle) {
                prevStyle.remove();
            }

            style.id = 'tenant-colors';
            document.head.appendChild(style);
        }
    });

    const classes = {
        // Font Families
        fontFamily: {
            title: 'font-title',
            subtitle: 'font-subtitle',
            content: 'font-content',
            label: 'font-label',
        },

        // Font Families
        fontFamilyClasses: [
            'font-title',
            'font-subtitle',
            'font-content',
            'font-label',
        ],

        // Global
        rounding: {
            rounded: 'rounded',
            sharp: 'rounded-none',
            bubbly: 'rounded-xl',
        },

        // Sections
        sectionPadding: {
            normal: [
                'py-12',
                'lg:py-24'
            ],
            compact: [
                'py-6',
                'lg:py-12'
            ],
            spacious: [
                'py-16',
                'lg:py-32'
            ],
        },
        sectionGap: {
            normal: [
                'gap-6',
                'lg:gap-12'
            ],
            compact: [
                'gap-3',
                'lg:gap-6'
            ],
            spacious: [
                'gap-8',
                'lg:gap-16'
            ],
        },
        sectionMinimumHeight: {
            'fitted': 'min-h-auto',
            'quarter': 'min-h-[25dvh]',
            'third': 'min-h-[33dvh]',
            'half': 'min-h-[50dvh]',
            'two-thirds': 'min-h-[66dvh]',
            'three-quarters': 'min-h-[75dvh]',
            'full': 'min-h-[100dvh] h-full',
        },

        // Content
        contentPadding: {
            normal: [
                'p-3',
                'lg:p-6'
            ],
            compact: [
                'p-1.5',
                'lg:p-3'
            ],
            spacious: [
                'p-4',
                'lg:p-8'
            ],
        },
        contentPaddingHalf: {
            normal: [
                'p-1.5',
                'lg:p-3'
            ],
            compact: [
                'p-0.5',
                'lg:p-1.5'
            ],
            spacious: [
                'p-2',
                'lg:p-4'
            ],
        },
        contentPaddingQuarter: {
            normal: [
                'p-0.5',
                'lg:p-1.5'
            ],
            compact: [
                'p-px',
                'lg:p-0.5'
            ],
            spacious: [
                'p-1',
                'lg:p-2'
            ],
        },
        contentGap: {
            normal: [
                'gap-3',
                'lg:gap-6'
            ],
            compact: [
                'gap-1.5',
                'lg:gap-3'
            ],
            spacious: [
                'gap-4',
                'lg:gap-8'
            ],
        },
        contentGapHalf: {
            normal: [
                'gap-1.5',
                'lg:gap-3'
            ],
            compact: [
                'gap-0.5',
                'lg:gap-1.5'
            ],
            spacious: [
                'gap-2',
                'lg:gap-4'
            ],
        },
        contentGapDouble: {
            normal: [
                'gap-6',
                'lg:gap-12'
            ],
            compact: [
                'gap-3',
                'lg:gap-6'
            ],
            spacious: [
                'gap-8',
                'lg:gap-16'
            ],
        },
        contentMarginBottom: {
            normal: [
                'mb-3',
                'lg:mb-6'
            ],
            compact: [
                'mb-1.5',
                'lg:mb-3'
            ],
            spacious: [
                'mb-4',
                'lg:mb-8'
            ],
        },
        contentMarginBottomDouble: {
            normal: [
                'mb-6',
                'lg:mb-12'
            ],
            compact: [
                'mb-3',
                'lg:mb-6'
            ],
            spacious: [
                'mb-8',
                'lg:mb-16'
            ],
        },
        contentMarginBottomHalf: {
            normal: [
                'mb-1.5',
                'lg:mb-3'
            ],
            compact: [
                'mb-0.5',
                'lg:mb-1.5'
            ],
            spacious: [
                'mb-2',
                'lg:mb-4'
            ],
        },
        contentMarginBottomQuarter: {
            normal: [
                'mb-0.5',
                'lg:mb-1.5'
            ],
            compact: [
                'mb-0',
                'lg:mb-0.5'
            ],
            spacious: [
                'mb-1',
                'lg:mb-2'
            ],
        },

        // Text
        textAlignment: {
            'left': 'text-left',
            'center': 'text-center',
            'right': 'text-right',
            'justify-left': 'text-justify text-last-left',
            'justify-center': 'text-justify text-last-center',
            'justify-right': 'text-justify text-last-right',
        },
        textTransform: {
            normal: 'normal-case',
            uppercase: 'uppercase',
            lowercase: 'lowercase',
            capitalize: 'capitalize',
        },
        letterSpacing: {
            tighter: 'tracking-tighter',
            tight: 'tracking-tight',
            normal: 'tracking-normal',
            wide: 'tracking-wide',
            wider: 'tracking-wider',
            widest: 'tracking-widest',
        },
        textSize: {
            'xs': 'text-xs',
            'sm': 'text-sm',
            'base': 'text-base',
            'lg': 'text-lg',
            'xl': 'text-xl',
            '2xl': 'text-2xl',
            '3xl': 'text-3xl',
            '4xl': 'text-4xl',
            '5xl': 'text-5xl',
            '6xl': 'text-6xl',
            '7xl': 'text-7xl',
        },
        fontWeight: {
            100: 'font-thin',
            200: 'font-extralight',
            300: 'font-light',
            400: 'font-normal',
            500: 'font-medium',
            600: 'font-semibold',
            700: 'font-bold',
            800: 'font-extrabold',
            900: 'font-black',
        },

        // Backgrounds
        bgPosition: {
            'center': 'bg-center',
            'left': 'bg-left',
            'right': 'bg-right',
            'top': 'bg-top',
            'bottom': 'bg-bottom',
            'left top': 'bg-left-top',
            'left bottom': 'bg-left-bottom',
            'right top': 'bg-right-top',
            'right bottom': 'bg-right-bottom',
        },

        // Images
        // We need the [&_img] classes for images when delivered through the CMS as v-html.
        imagePosition: {
            'center': 'object-center [&_img]:object-center',
            'left': 'object-left [&_img]:object-left',
            'right': 'object-right [&_img]:object-right',
            'top': 'object-top [&_img]:object-top',
            'bottom': 'object-bottom [&_img]:object-bottom',
            'left top': 'object-left-top [&_img]:object-left-top',
            'left bottom': 'object-left-bottom [&_img]:object-left-bottom',
            'right top': 'object-right-top [&_img]:object-right-top',
            'right bottom': 'object-right-bottom [&_img]:object-right-bottom',
        },
        imageAspectRatio: {
            picture: 'aspect-picture size-full max-h-dvh object-cover [&_img]:aspect-picture [&_img]:size-full [&_img]:max-h-dvh [&_img]:object-cover',
            portrait: 'aspect-portrait size-full max-h-dvh object-cover [&_img]:aspect-portrait [&_img]:size-full [&_img]:max-h-dvh [&_img]:object-cover',
            square: 'aspect-square size-full max-h-dvh object-cover [&_img]:aspect-square [&_img]:size-full [&_img]:max-h-dvh [&_img]:object-cover',
            full: 'aspect-full size-full max-h-dvh object-cover [&_img]:aspect-full [&_img]:size-full [&_img]:max-h-dvh [&_img]:object-cover',
            wide: 'aspect-wide size-full max-h-dvh object-cover [&_img]:aspect-wide [&_img]:size-full [&_img]:max-h-dvh [&_img]:object-cover',
            ultrawide: 'aspect-ultrawide size-full max-h-dvh object-cover [&_img]:aspect-ultrawide [&_img]:size-full [&_img]:max-h-dvh [&_img]:object-cover',
            unset: 'aspect-auto w-full max-h-dvh [&_img]:aspect-auto [&_img]:w-full [&_img]:max-h-dvh',
        },
        opticalAdjustement: {
            unset: 'p-4',
            wide: 'px-0',
            tall: 'py-0',
            square: 'p-8',
        },

        // Layout
        layoutGrid: {
            1: [
                'grid-cols-1',
            ],
            2: [
                'grid-cols-1',
                'sm:grid-cols-2',
            ],
            3: [
                'grid-cols-1',
                'md:grid-cols-2',
                'lg:grid-cols-3',
            ],
            4: [
                'grid-cols-1',
                'sm:grid-cols-2',
                'lg:grid-cols-4',
            ],
            5: [
                'grid-cols-1',
                'sm:grid-cols-2',
                'lg:grid-cols-3',
                'xl:grid-cols-5',
            ],
            6: [
                'grid-cols-1',
                'sm:grid-cols-2',
                'lg:grid-cols-3',
                'xl:grid-cols-6',
            ],
            7: [
                'grid-cols-1',
                'sm:grid-cols-2',
                'lg:grid-cols-3',
                'xl:grid-cols-7',
            ],
            8: [
                'grid-cols-1',
                'sm:grid-cols-2',
                'lg:grid-cols-4',
                'xl:grid-cols-8',
            ],
        },

        socialMediaIcon: {
            facebook: 'socials/fcebook',
            twitter: 'socials/x-twitter',
            instagram: 'socials/instagram',
            linkedin: 'socials/linkedin',
            youtube: 'socials/youtube',
            tiktok: 'socials/tiktok',
        },
    };

    const getBackgroundOverlay = function(color = 'darken', opacity = 50) {
        const float = opacity / 100;
        const calculatedOpacity = Math.round(float * 100) / 100;

        const primaryRgb = hexToRgb(globals.value?.colors?.primary || fallbackColors.primary);

        const overlays = {
            darken: `0, 0, 0, ${calculatedOpacity}`,
            lighten: `255, 255, 255, ${calculatedOpacity}`,
            primary: `${primaryRgb.r}, ${primaryRgb.g}, ${primaryRgb.b}, ${calculatedOpacity}`,
        };

        return overlays[color] || overlays.darken;
    };

    return {
        navs,
        globals: computed(() => ({
            ...globals.value,
        })),
        classes: computed(() => {
            const spacing = globals.value?.styles.spacing.value || 'normal';
            return {
                ...classes,
                imageFramed: `bg-white shadow-md border border-gray-300 ${classes.contentPaddingHalf[spacing]}`,
            };
        }),

        // Functions
        getBackgroundOverlay,
    };
}
