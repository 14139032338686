<template>
    <component
        :is="definedTag"
        :class="[
            'relative inline-flex items-center justify-center gap-2 shadow',
            'transition duration-300',
            sizeClasses,
            disabledClasses,
            classes.rounding[globals?.styles.rounding.value || 'rounded'],
            `text-tenant-${textColor} bg-tenant-${bgColor}`,
        ]"
        :disabled="disabled"
        :href="href"
        :rel="relValues"
        v-bind="$attrs"
        @click="handleClick"
    >
        {{ text }}
        <slot v-if="!text" />
    </component>
</template>

<script lang="ts" setup>
    import { Link } from '@inertiajs/vue3';
    import { gtag } from '@aspect/shared/utils/gtag.ts';
    import { useSite } from '@/site/composables/use-site.ts';

    defineOptions({
        inheritAttrs: false,
    });

    const props = withDefaults(defineProps<{
        text?: string;
        disabled?: boolean;
        href?: any;
        tag?: string;
        size?: 'sm' | 'md' | 'lg';
        isEntry?: boolean;
        trackingId?: string;
        bgColor?: string;
        textColor?: string;
    }>(), {
        size: 'md',
        isEntry: false,
        bgColor: 'primary',
        textColor: 'white',
    });

    const { classes, globals } = useSite();

    const relValues = computed(() => {
        if (props.href?.startsWith('http')) {
            return 'external';
        }
        return undefined;
    });

    const definedTag = computed(() => {
        if (props.isEntry) {
            return Link;
        }

        if (props.disabled) {
            return 'button';
        }

        if (props.tag) {
            return props.tag;
        }

        return 'a';
    });

    const sizeClasses = computed(() => {
        return {
            sm: 'px-3 py-2 text-sm',
            md: 'px-4 py-3 text-base',
            lg: 'px-6 py-4 text-lg',
        }[props.size];
    });

    const disabledClasses = computed(() => {
        if (props.disabled) {
            return 'text-gray-500 bg-gray-100 cursor-not-allowed border-gray-200 shadow-none hover:shadow-none';
        }
        return 'hover:shadow-lg hover:brightness-125';
    });

    function handleClick() {
        if (props.trackingId) {
            gtag({ event: 'button_click', value: props.trackingId  });
        }
    }
</script>
