<!-- eslint-disable vue/no-v-html -->
<template>
    <section
        :id="section.section_id"
        :class="[
            classes.sectionPadding[globals.styles.spacing?.value || ''],
            section.background_image && 'bg-cover bg-no-repeat',
            section.background_image && classes.bgPosition[section.background_image_position?.value || ''],
            `bg-tenant-${section.background_color?.value || 'white'}`,
        ]"
        :style="[
            section.background_image && `background-image: linear-gradient(to right, rgba(${backgroundOverlay}), rgba(${backgroundOverlay})), url(${section.background_image.url});`,
        ]"
    >
        <SiteHeading
            v-if="section.title"
            :title="section.title"
            :title-color="section.title_text_color?.value"
            :subtitle="section.sub_title"
            :subtitle-color="section.subtitle_text_color?.value"
            :text-alignment="section.title_alignment?.value"
            :overlay-color="section.background_image ? section.background_overlay_color?.value : undefined"
        />

        <div
            :class="[
                'container grid grid-cols-6 items-center',
                classes.sectionGap[globals.styles.spacing?.value || ''],
            ]"
        >
            <figure
                v-if="section.image_side?.value == 'left'"
                :class="[
                    imageColumnClasses[section.layout?.value],
                    classes.rounding[globals.styles.rounding?.value || 'rounded'],
                ]"
            >
                <img
                    v-if="section.image"
                    :alt="section.image?.alt || ''"
                    :class="[
                        classes.rounding[globals.styles.rounding?.value || 'rounded'],
                        classes.imagePosition[section.image_position?.value || ''],
                        classes.imageAspectRatio[section.image_aspect_ratio?.value || ''],
                    ]"
                    :height="section.image?.height || ''"
                    :src="section.image?.url || ''"
                    :width="section.image?.width || ''"
                >
            </figure>

            <div :class="textColumnClasses[section.layout?.value]">
                <div
                    :class="[
                        'flex flex-col items-start',
                        classes.contentGap[globals.styles.spacing?.value || ''],
                        section.text_alignment?.value.endsWith('left') && 'items-start',
                        section.text_alignment?.value.endsWith('center') && 'items-center',
                        section.text_alignment?.value.endsWith('right') && 'items-end',
                    ]"
                >
                    <div
                        :class="[
                            'prose max-w-full',
                            classes.textAlignment[section.text_alignment?.value || ''],
                            `text-tenant-${section.text_color?.value || 'black'}`,
                        ]"
                        v-html="section.content || ''"
                    />

                    <div v-if="section.button">
                        <SiteButton
                            :href="linkTypeHref(section)"
                            :is-entry="section.button_link?.startsWith('/')"
                            :target="section.button_target ? '_blank' : '_self'"
                            :tracking-id="section.button_tracking_id"
                            :bg-color="section.button_background_color?.value || 'primary'"
                            :text-color="section.button_text_color?.value || 'white'"
                        >
                            {{ section.button_text || '' }}
                        </SiteButton>
                    </div>
                </div>
            </div>

            <figure
                v-if="section.image_side?.value == 'right'"
                :class="[
                    imageColumnClasses[section.layout?.value],
                    classes.rounding[globals.styles.rounding?.value || 'rounded'],
                    'order-first lg:order-none',
                ]"
            >
                <img
                    v-if="section.image"
                    :alt="section.image?.alt || ''"
                    :class="[
                        classes.rounding[globals.styles.rounding?.value || 'rounded'],
                        classes.imagePosition[section.image_position?.value || ''],
                        classes.imageAspectRatio[section.image_aspect_ratio?.value || ''],
                    ]"
                    :height="section.image?.height || ''"
                    :src="section.image?.url || ''"
                    :width="section.image?.width || ''"
                >
            </figure>
        </div>
    </section>
</template>

<script lang="ts" setup>
    import { useSite } from '@/site/composables/use-site.ts';

    import SiteButton from '@/site/components/site-button.vue';
    import SiteHeading from '@/site/components/site-heading.vue';
    const props = defineProps<{
        section: any;
    }>();

    const { classes, globals, getBackgroundOverlay } = useSite();

    const backgroundOverlay = computed(() => {
        return getBackgroundOverlay(props.section.background_overlay_color?.value, props.section.background_overlay_opacity);
    });

    const imageColumnClasses = {
        large_image: 'col-span-full lg:col-span-4',
        small_image: 'col-span-full lg:col-span-2',
        equal_image: 'col-span-full lg:col-span-3',
    };

    const textColumnClasses = {
        large_image: 'col-span-full lg:col-span-2',
        small_image: 'col-span-full lg:col-span-4',
        equal_image: 'col-span-full lg:col-span-3',
    };

    function linkTypeHref(section) {
        // Hides link if button is disabled
        if (section.button_disabled) {
            return;
        }

        // Files
        if (section.button_link_type?.value === 'file') {
            return section.button_file?.url || '#';
        }

        // Email
        if (section.button_link_type?.value === 'email' && section.button_email_address) {
            return section.button_full_name ? `mailto:${section.button_full_name}<${section.button_email_address}>` : `mailto:${section.button_email_address}`;
        }

        // Phone
        if (section.button_link_type?.value === 'phone' && section.button_phone_number) {
            return `tel:${section.button_phone_number}`;
        }

        // Webpages
        if (section.button_link_type?.value === 'webpage') {
            return `${section.button_link || ''}${section.button_fragment_identifier ? `#${section.button_fragment_identifier}` : ''}`;
        }


        // Default fallback
        return '#';
    }
</script>
