<template>
    <section
        :id="section.section_id"
        :class="[
            'flex flex-col justify-center bg-cover bg-no-repeat',
            classes.bgPosition[section.background_image_position?.value || ''],
            classes.sectionPadding[globals.styles.spacing?.value || ''],
            `bg-tenant-${section.background_color?.value || 'white'}`,
        ]"
        :style="[
            section.background_image && `background-image: linear-gradient(to right, rgba(${backgroundOverlay}), rgba(${backgroundOverlay})), url(${section.background_image.url});`,
        ]"
    >
        <SiteHeading
            v-if="section.title"
            :title="section.title"
            :title-color="section.title_text_color?.value"
            :subtitle="section.sub_title"
            :subtitle-color="section.subtitle_text_color?.value"
            :text-alignment="section.title_alignment?.value"
            :overlay-color="section.background_image ? section.background_overlay_color?.value : undefined"
        />

        <div class="container grid grid-cols-3 justify-center">
            <div />
            <div id="mc_embed_shell">
                <div id="mc_embed_signup">
                    <form
                        id="mc-embedded-subscribe-form"
                        :action="constructedUrl"
                        class="validate"
                        method="post"
                        name="mc-embedded-subscribe-form"
                        novalidate
                        target="_self"
                    >
                        <div id="mc_embed_signup_scroll" class="flex flex-col gap-6">
                            <AspectInputGroup class="mc-field-group">
                                <SiteLabel
                                    class="flex items-center gap-2"
                                    :text-color="contentTextColor"
                                    html-for="mce-EMAIL"
                                >
                                    {{ t('Email Address') }}
                                    <AspectIcon class="asterisk size-3" name="asterisk-1" />
                                </SiteLabel>

                                <AspectInput
                                    id="mce-EMAIL"
                                    :class="[
                                        'border-2',
                                        'required email',
                                        classes.rounding[globals.styles.rounding?.value || 'rounded'],
                                        `text-tenant-${contentTextColor}`,
                                        `bg-tenant-${inputBackgroundColor}`,
                                        `border-tenant-primary`,
                                    ]"
                                    wrapper-class="border-transparent ring-0"
                                    model-value=""
                                    name="EMAIL"
                                    required="true"
                                    type="email"
                                />
                            </AspectInputGroup>

                            <AspectInputGroup v-if="section.phone" class="mc-field-group">
                                <SiteLabel
                                    class="flex items-center gap-2"
                                    :text-color="contentTextColor"
                                    html-for="mce-PHONE"
                                >
                                    {{ t('Phone Number') }}
                                    <AspectIcon
                                        v-if="section.phone_required"
                                        class="asterisk size-3"
                                        name="asterisk-1"
                                    />
                                </SiteLabel>

                                <AspectInput
                                    id="mce-PHONE"
                                    :class="[
                                        'border-2',
                                        section.phone && 'REQ_CSS',
                                        classes.rounding[globals.styles.rounding?.value || 'rounded'],
                                        `text-tenant-${contentTextColor}`,
                                        `bg-tenant-${inputBackgroundColor}`,
                                        `border-tenant-primary`,
                                    ]"
                                    wrapper-class="border-transparent ring-0"
                                    model-value=""
                                    name="PHONE"
                                    required="true"
                                    type="text"
                                />
                            </AspectInputGroup>

                            <AspectInputGroup v-if="section.first_name" class="mc-field-group">
                                <SiteLabel
                                    class="flex items-center gap-2"
                                    :text-color="contentTextColor"
                                    for="mce-FNAME"
                                >
                                    {{ t('First Name') }}
                                    <AspectIcon
                                        v-if="section.first_name_required"
                                        class="asterisk size-3"
                                        name="asterisk-1"
                                    />
                                </SiteLabel>

                                <AspectInput
                                    id="mce-FNAME"
                                    :class="[
                                        'border-2',
                                        'text',
                                        section.first_name_required && 'required',
                                        classes.rounding[globals.styles.rounding?.value || 'rounded'],
                                        `text-tenant-${contentTextColor}`,
                                        `bg-tenant-${inputBackgroundColor}`,
                                        `border-tenant-primary`,
                                    ]"
                                    wrapper-class="border-transparent ring-0"
                                    model-value=""
                                    name="FNAME"
                                    required="true"
                                    type="text"
                                />
                            </AspectInputGroup>

                            <AspectInputGroup v-if="section.last_name" class="mc-field-group">
                                <SiteLabel
                                    class="flex items-center gap-2"
                                    :text-color="contentTextColor"
                                    html-for="mce-LNAME"
                                >
                                    {{ t('Last Name') }}
                                    <AspectIcon
                                        v-if="section.last_name_required"
                                        class="asterisk size-3"
                                        name="asterisk-1"
                                    />
                                </SiteLabel>

                                <AspectInput
                                    id="mce-LNAME"
                                    :class="[
                                        'border-2',
                                        'text',
                                        section.last_name_required && 'required',
                                        classes.rounding[globals.styles.rounding?.value || 'rounded'],
                                        `text-tenant-${contentTextColor}`,
                                        `bg-tenant-${inputBackgroundColor}`,
                                        `border-tenant-primary`,
                                    ]"
                                    wrapper-class="border-transparent ring-0"
                                    model-value=""
                                    name="LNAME"
                                    required="true"
                                    type="text"
                                />
                            </AspectInputGroup>

                            <SiteButton
                                id="mc-embedded-subscribe"
                                :value="t('Subscribe')"
                                class="button cursor-pointer"
                                :bg-color="section.button_background_color?.value || 'primary'"
                                :text-color="section.button_text_color?.value || 'white'"
                                name="subscribe"
                                tag="input"
                                type="submit"
                            />

                            <div id="mce-responses" class="clear foot">
                                <div
                                    id="mce-error-response"
                                    class="response"
                                    style="display: none;"
                                />
                                <div
                                    id="mce-success-response"
                                    class="response"
                                    style="display: none;"
                                />
                            </div>
                            <div aria-hidden="true" style="position: absolute; left: -5000px;">
                                <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups -->
                                <input
                                    :name="`b_${userId}_${id}`"
                                    tabindex="-1"
                                    type="text"
                                    value=""
                                >
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
    import { useSite } from '@/site/composables/use-site.ts';
    import { t } from '@aspect/shared/plugins/i18n.ts';

    import SiteButton from '@/site/components/site-button.vue';
    import SiteHeading from '@/site/components/site-heading.vue';
    import SiteLabel from '@/site/components/site-label.vue';
    import AspectInput from '@aspect/shared/components/aspect-input.vue';
    import AspectInputGroup from '@aspect/shared/components/aspect-input-group.vue';
    import AspectIcon from '@aspect/shared/components/aspect-icon.vue';

    const props = defineProps<{
        section: any;
    }>();

    const { classes, globals, getBackgroundOverlay } = useSite();

    const backgroundOverlay = computed(() => {
        return getBackgroundOverlay(props.section.background_overlay_color?.value, props.section.background_overlay_opacity);
    });

    const providedUrl = computed(() => props.section.form_url);

    const subdomain = computed(() => {
        const match = providedUrl.value.match(/\/\/(.*?)\./);
        return match ? match[1] : null;
    });

    const serverName = computed(() => {
        const match = providedUrl.value.match(/\/\/.*?\.(.*?)\./);
        return match ? match[1] : null;
    });

    const userId = computed(() => {
        const match = providedUrl.value.match(/u=(.*?)&/);
        return match ? match[1] : null;
    });

    const id = computed(() => {
        const match = providedUrl.value.match(/id=(.*?)&/);
        return match ? match[1] : null;
    });

    const formId = computed(() => {
        const match = providedUrl.value.match(/f_id=(.*?)(?:&|$)/);
        return match ? match[1] : null;
    });

    const constructedUrl = computed(() => {
        return `https://${subdomain.value}.${serverName.value}.list-manage.com/subscribe/post?u=${userId.value}&id=${id.value}&f_id=${formId.value}`;
    });

    const contentTextColor = computed(() => {
        if (props.section.content_text_color?.value) {
            return props.section.content_text_color?.value;
        }

        return props.section.background_overlay_color?.value === 'darken' ? 'white' : 'black';
    });

    const inputBackgroundColor = computed(() => {
        return props.section.background_overlay_color?.value === 'darken' ? 'black' : 'white';
    });
</script>
