<template>
    <section ref="element" class="isolate flex scroll-mt-16 flex-col bg-gray-50">
        <ReservationDayPicker
            v-model:active-day="activeDay"
            v-model:selected-day="selectedDay"
            :loading="loading"
            :slots-by-day="slotsByDay"
            @click="show"
        />

        <div v-if="(showPartySizeFilter || showLocationFilter)" :class="[!showSlots && 'rounded-b', 'flex w-full flex-col items-center justify-between xl:flex-row']">
            <ReservationPartySizePicker
                v-if="showPartySizeFilter"
                v-model="partySize"
                :disabled="!!selectedSlot"
                :show-slots="showSlots && !!selectedDay"
                :only-filter="!showLocationFilter"
                class="h-16 w-full lg:flex-1"
            />

            <ReservationLocationPicker
                v-if="showLocationFilter"
                v-model="location"
                :disabled="!!selectedSlot"
                :locations="locations"
                :show-slots="showSlots && !!selectedDay"
                :only-filter="!showPartySizeFilter"
                class="h-16 w-full lg:flex-1"
            />
        </div>

        <template v-if="selectedDay">
            <div v-if="showSlots" class="border-t border-gray-200 ">
                <AspectSlotTimePicker
                    v-if="showTimePicker"
                    class="sticky top-16"
                    :slots="filteredSlots"
                    :check-capacity="true"
                />
                <ReservationSlotPicker
                    v-model:active-slot="activeSlot"
                    v-model:show-unavailable-slots="showUnavailableSlots"
                    class="first:rounded-t last:rounded-b"
                    :slots="filteredSlots"
                    :offering-step-loading="offeringStepLoading"
                    :selected-day="selectedDay"
                    @select="onSelect"
                />
            </div>

            <AspectButtonAttached
                class="rounded-b border-t border-gray-200"
                color="white"
                @click="toggle"
            >
                <AspectIcon v-if="showSlots" class="size-3 md:size-5" name="arrow-up-3" />
                <AspectIcon v-else class="size-3 md:size-5" name="arrow-down-3" />
            </AspectButtonAttached>
        </template>
    </section>
</template>

<script lang="ts" setup>
    import { computed, ref, watch, nextTick, onMounted } from 'vue';

    import { date } from '@aspect/shared/utils/date.ts';

    import { usePageProps } from '@aspect/shared/composables/use-page-props.ts';
    import { useTicketOfficeStore } from '@aspect/ticket-office/stores/use-ticket-office-store.ts';

    import AspectIcon from '@aspect/shared/components/aspect-icon.vue';
    import AspectSlotTimePicker from '@aspect/shared/components/aspect-slot-time-picker.vue';
    import AspectButtonAttached from '@aspect/shared/components/aspect-button-attached.vue';

    import ReservationDayPicker from '@aspect/ticket-office/components/reservation-day-picker.vue';
    import ReservationSlotPicker from '@aspect/ticket-office/components/reservation-slot-picker.vue';

    import type { Dayjs } from 'dayjs';
    import type { LocationData, SlotData } from '@aspect/shared/types/generated';
    import ReservationPartySizePicker from './reservation-party-size-picker.vue';
    import ReservationLocationPicker from './reservation-location-picker.vue';
    import { t } from '@aspect/shared/plugins/i18n';

    const props = withDefaults(defineProps<{
        slotsByDay: Record<string, SlotData[]>;
        loading: boolean;
        offeringStepLoading: boolean;
        autoSelectToday?: boolean;
        showPartySizeFilter?: boolean;
        showLocationFilter?: boolean;
        defaultPartySize?: number | null;
        locations?: LocationData[];
    }>(), {
        autoSelectToday: false,
        showPartySizeFilter: false,
        showLocationFilter: false,
        defaultPartySize: 4,
        locations: () => [],
    });

    const store = useTicketOfficeStore();
    const pageProps = usePageProps();


    // DIVISION
    const division = computed(() => pageProps.value.division);


    // ACTIVE DAY
    const activeDay = defineModel<Dayjs>('activeDay', { required: true });

    watch(activeDay, () => {
        selectedDay.value = null;
    });


    // SELECTED SLOT
    const selectedSlot = defineModel<SlotData | null>('selectedSlot', { required: true });


    // ACTIVE SLOT
    const activeSlot = defineModel<SlotData | null>('activeSlot', { required: true });

    // PARTY SIZE
    const partySize = defineModel<number | null>('partySize', { required: true });

    // LOCATION
    const location = defineModel<string | null>('location', { required: true });


    // SHOW UNAVAILABLE SLOTS
    const showUnavailableSlots = ref(false);

    onMounted(() => {
        showUnavailableSlots.value = store.memberBooking || division.value.settings.entryType === 'time';
    });


    // SHOW SLOTS
    const showSlots = defineModel<boolean>('showSlots', { required: true });

    watch(showSlots, (value) => {
        if (value) {
            selectedSlot.value = null;
        }
    });

    function show() {
        showSlots.value = true;
    }

    function hide(scroll = true) {
        if (!showSlots.value) {
            return;
        }

        showSlots.value = false;

        if (scroll) {
            onScroll();
        }
    }

    function toggle() {
        if (showSlots.value) {
            hide();
        } else {
            show();
        }
    }

    watch(showSlots, async (value) => {
        await nextTick();

        if (value && activeSlot.value) {
            const activeSlotElement = document.querySelector(`[data-slot="${activeSlot.value.id}"]`);

            activeSlotElement?.scrollIntoView({ behavior: 'smooth' });
        }
    });


    // SELECTED DAY
    const selectedDay = defineModel<Dayjs | null>('selectedDay', { required: true });

    watch(selectedDay, (value) => {
        activeSlot.value = null;
        selectedSlot.value = null;

        if (value) {
            show();
        } else {
            hide(false);
        }
    });

    watch(() => props.slotsByDay, () => {
        if (!props.autoSelectToday || selectedDay.value || !activeDay.value.isSame(date(), 'day')) {
            return;
        }

        const today = activeDay.value.format('YYYY-MM-DD');
        const todaySlots = props.slotsByDay[today] || [];

        if (todaySlots.some(slot => slot.isAvailable)) {
            selectedDay.value = activeDay.value;
        }
    });


    // FILTERED SLOTS
    const filteredSlots = computed(() => {
        if (!selectedDay.value) {
            return [];
        }

        const slots = props.slotsByDay[selectedDay.value.format('YYYY-MM-DD')] || [];

        return slots.filter(slot => {
            return showUnavailableSlots.value || slot.isSelectable;
        });
    });


    // ON SELECT
    function onSelect() {
        if (!activeSlot.value) {
            return;
        }

        selectedSlot.value = activeSlot.value;
    }

    watch(() => props.offeringStepLoading, (value) => {
        if (!value && selectedSlot.value) {
            hide(false);
        }
    });


    // SCROLL
    const element = ref<HTMLElement>();

    async function onScroll() {
        await nextTick();

        element.value?.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }


    // SHOW TIME PICKER
    const showTimePicker = computed(() => {
        return division.value.settings.entryType === 'person' && !!filteredSlots.value.length;
    });

    const showFilters = ref(false);

    function getLocationName() {
        return props.locations.find(locationItem => locationItem.id === location.value)?.name || t('All');
    }
</script>
