<template>
    <div :class="['grid grid-cols-4 divide-x border-t border-gray-200', !showSlots && 'rounded-b']">
        <AspectButtonAttached
            v-for="size in 4"
            :key="size"
            :disabled="disabled"
            :indicator="partySize === size"
            indicator-color="black"
            :class="[
                !showSlots && 'md:first:rounded-bl',
                onlyFilter && !showSlots && 'md:last:rounded-br',
            ]"
            @click="partySize = size"
        >
            <AspectIcon
                v-if="size >= 1"
                name="user-single-neutral"
                class="size-4"
            />
            <AspectIcon
                v-if="size >= 2"
                name="user-single-neutral"
                class="-ml-3.5 size-4"
            />
            <AspectIcon
                v-if="size >= 3"
                name="user-single-neutral"
                class="-ml-3.5 size-4"
            />
            <AspectIcon
                v-if="size >= 4"
                name="user-single-neutral"
                class="-ml-3.5 size-4"
            />
        </AspectButtonAttached>
    </div>
</template>

<script setup lang="ts">
    import AspectButtonAttached from '@aspect/shared/components/aspect-button-attached.vue';
    import AspectIcon from '@aspect/shared/components/aspect-icon.vue';

    defineProps<{
        disabled: boolean;
        showSlots: boolean;
        onlyFilter: boolean;
    }>();

    const partySize = defineModel<number | null>({ required: true });
</script>
