<!-- eslint-disable vue/no-v-html -->
<template>
    <section
        :id="section.section_id"
        :class="[
            classes.sectionPadding[globals.styles.spacing?.value || ''],
            section.background_image && 'bg-cover bg-no-repeat',
            section.background_image && classes.bgPosition[section.background_image_position?.value || ''],
            `bg-tenant-${section.background_color?.value || 'white'}`
        ]"
        :style="[
            section.background_image && `background-image: linear-gradient(to right, rgba(${backgroundOverlay}), rgba(${backgroundOverlay})), url(${section.background_image.url});`,
        ]"
    >
        <SiteHeading
            v-if="section.title"
            :title="section.title"
            :title-color="section.title_text_color?.value"
            :subtitle="section.sub_title"
            :subtitle-color="section.subtitle_text_color?.value"
            :text-alignment="section.title_alignment?.value"
            :overlay-color="section.background_image ? section.background_overlay_color?.value : undefined"
        />

        <div
            :class="[
                'container grid items-start',
                classes.layoutGrid[section.column_quantity?.value || ''],
                classes.sectionGap[globals.styles.spacing?.value || ''],
            ]"
        >
            <article
                v-for="person in section.team || []"
                :key="person.id"
                :class="['flex flex-col', classes.contentGapHalf[globals.styles.spacing?.value || '']]"
            >
                <figure :class="[classes.rounding[globals.styles.rounding?.value || 'rounded'], classes.imageAspectRatio[person.image_aspect_ratio?.value || '']]">
                    <img
                        v-if="person.image"
                        :alt="person.image.alt"
                        :class="[
                            'size-full border border-gray-100 object-cover',
                            classes.rounding[globals.styles.rounding?.value || 'rounded'],
                        ]"
                        :height="person.image.height"
                        :src="person.image.url"
                        :width="person.image.width"
                    >
                </figure>

                <div
                    :class="[
                        'flex flex-col',
                        `text-tenant-${section.content_text_color?.value}`,
                        classes.contentGap[globals.styles.spacing?.value || ''],
                    ]"
                >
                    <div>
                        <SiteHeadingContent :text-color="section.name_text_color?.value">
                            {{ person.first_name || '' }} {{ person.last_name || '' }}
                        </SiteHeadingContent>
                        <SiteLabel :text-color="section.job_title_text_color?.value">
                            {{ person.title || '' }}
                        </SiteLabel>
                    </div>
                    <div
                        v-if="person.linkedin || person.facebook || person.instagram || person.twitter || person.youtube || person.website"
                        :class="['flex items-center', classes.contentGapHalf[globals.styles.spacing?.value || '']]"
                    >
                        <a
                            v-if="person.website"
                            :href="person.website_url"
                            target="_blank"
                        >
                            <AspectIcon class="hover:text-tenant-primary block size-6 transition" name="web" />
                        </a>
                        <a
                            v-if="person.linkedin"
                            :href="person.linkedin_url"
                            target="_blank"
                        >
                            <AspectIcon
                                :filled="true"
                                class="block size-6 transition hover:text-linkedin"
                                name="socials/linkedin"
                            />
                        </a>
                        <a
                            v-if="person.facebook"
                            :href="person.facebook_url"
                            target="_blank"
                        >
                            <AspectIcon
                                :filled="true"
                                class="block size-6 transition hover:text-facebook"
                                name="socials/facebook"
                            />
                        </a>
                        <a
                            v-if="person.instagram"
                            :href="person.instagram_url"
                            target="_blank"
                        >
                            <AspectIcon
                                :filled="true"
                                class="block size-6 transition hover:text-instagram"
                                name="socials/instagram"
                            />
                        </a>
                        <a
                            v-if="person.twitter"
                            :href="person.twitter_url"
                            target="_blank"
                        >
                            <AspectIcon
                                :filled="true"
                                class="block size-6 transition hover:text-x-twitter"
                                name="socials/x-twitter"
                            />
                        </a>
                        <a
                            v-if="person.youtube"
                            :href="person.youtube_url"
                            target="_blank"
                        >
                            <AspectIcon
                                :filled="true"
                                class="block size-6 transition hover:text-youtube"
                                name="socials/youtube"
                            />
                        </a>
                    </div>
                    <div
                        :class="[
                            'flex flex-wrap justify-between',
                            classes.contentGapHalf[globals.styles.spacing?.value || ''],
                        ]"
                    >
                        <SiteButton
                            v-if="person.mobile_phone_number"
                            :href="formattedMobilePhoneNumberUrl(person)"
                            class="flex-1"
                            size="sm"
                            tag="a"
                        >
                            <AspectIcon :class="`size-6 text-tenant-${section.button_text_color?.value}`" name="phone-mobile-phone" />
                        </SiteButton>
                        <SiteButton
                            v-if="person.office_phone_number"
                            :href="formattedOfficePhoneNumberUrl(person)"
                            class="flex-1"
                            size="sm"
                            tag="a"
                        >
                            <AspectIcon :class="`size-6 text-tenant-${section.button_text_color?.value}`" name="phone" />
                        </SiteButton>
                        <SiteButton
                            v-if="person.email"
                            :href="formattedEmailAddressUrl(person)"
                            class="flex-1"
                            size="sm"
                            tag="a"
                        >
                            <AspectIcon :class="`size-6 text-tenant-${section.button_text_color?.value}`" name="mail-send-envelope" />
                        </SiteButton>
                    </div>
                </div>
            </article>
        </div>
    </section>
</template>

<script lang="ts" setup>
    import { useSite } from '@/site/composables/use-site.ts';

    import SiteButton from '@/site/components/site-button.vue';
    import SiteHeading from '@/site/components/site-heading.vue';
    import SiteLabel from '@/site/components/site-label.vue';
    import SiteHeadingContent from '@/site/components/site-heading-content.vue';

    import AspectIcon from '@aspect/shared/components/aspect-icon.vue';

    const props = defineProps<{
        section: any;
    }>();

    const { classes, globals, getBackgroundOverlay } = useSite();

    const backgroundOverlay = computed(() => {
        return getBackgroundOverlay(props.section.background_overlay_color?.value, props.section.background_overlay_opacity);
    });

    const formattedMobilePhoneNumberUrl = (person) => {
        return person.mobile_phone_number && `tel:${person.mobile_phone_number.replace(/\D/g, '')}`;
    };

    const formattedOfficePhoneNumberUrl = (person) => {
        const formattedNumber = person.office_phone_number && person.office_phone_number.replace(/\D/g, '');
        const formattedExtension = person.office_extension && person.office_extension.replace(/\D/g, '');

        if (person.office_extension) {
            if (person.extension_behaviour === 'pause') {
                return `tel:${formattedNumber},${formattedExtension}`;
            }
            return `tel:${formattedNumber};${formattedExtension}`;
        }

        return `tel:${formattedNumber}`;
    };

    const formattedEmailAddressUrl = (person) => {
        return person.email && `mailto:${person.first_name} ${person.last_name} <${person.email}>`;
    };
</script>
