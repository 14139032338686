<!-- eslint-disable vue/no-v-html -->
<template>
    <section
        :id="section.section_id"
        :class="[
            classes.sectionPadding[globals.styles.spacing?.value || ''],
            section.background_image && 'bg-cover bg-no-repeat',
            section.background_image && classes.bgPosition[section.background_image_position?.value || ''],
            `bg-tenant-${section.background_color?.value || 'white'}`,
        ]"
        :style="[
            section.background_image && `background-image: linear-gradient(to right, rgba(${backgroundOverlay}), rgba(${backgroundOverlay})), url(${section.background_image.url});`,
        ]"
    >
        <SiteHeading
            v-if="section.title"
            :title="section.title"
            :title-color="section.title_text_color?.value"
            :subtitle="section.sub_title"
            :subtitle-color="section.subtitle_text_color?.value"
            :text-alignment="section.title_alignment?.value"
            :overlay-color="section.background_image ? section.background_overlay_color?.value : undefined"
        />

        <article
            :class="[
                'container grid grid-cols-1 items-center md:grid-cols-6 lg:grid-cols-3 lg:items-start',
                classes.sectionGap[globals.styles.spacing?.value || ''],
            ]"
        >
            <figure
                :class="[
                    'col-span-1 md:col-span-3 lg:col-span-1',
                    classes.rounding[globals.styles.rounding?.value || 'rounded'],
                ]"
            >
                <img
                    v-if="section.image"
                    :alt="section.image?.alt || ''"
                    :class="[
                        classes.rounding[globals.styles.rounding?.value || 'rounded'],
                        classes.imagePosition[section.image_position?.value || ''],
                        classes.imageAspectRatio[section.image_aspect_ratio?.value || ''],
                    ]"
                    :height="section.image?.height || ''"
                    :src="section.image?.url || ''"
                    :width="section.image?.width || ''"
                >
            </figure>

            <div
                :class="[
                    'col-span-1 flex flex-col items-stretch md:col-span-3 lg:col-span-1',
                    classes.contentGapHalf[globals.styles.spacing?.value || ''],
                    `text-tenant-${section.content_text_color?.value || 'black'}`,
                ]"
            >
                <div v-if="section.phone_number" class="flex items-center gap-3 text-lg">
                    <AspectIcon class="size-5" name="phone" />
                    <a :href="formattedPhoneNumberUrl">
                        {{ formattedPhoneNumber || '' }}
                        <template v-if="section.has_extension && section.extension">
                            ext. {{ section.extension || '' }}
                        </template>
                    </a>
                </div>

                <div v-if="section.email" class="flex items-center gap-3 text-lg">
                    <AspectIcon class="size-5" name="mail-send-envelope" />
                    <a :href="`mailto:${section.email}`">
                        {{ section.email || '' }}
                    </a>
                </div>

                <div class="flex items-start gap-3 text-lg">
                    <AspectIcon class="mt-1.5 size-5" name="map-location" />
                    <div>
                        <p v-if="section.location_name">
                            <strong>
                                {{ section.location_name || '' }}
                            </strong>
                        </p>
                        <p v-if="section.unit_apt || section.street_number || section.street_name">
                            <span v-if="section.unit_apt">{{ section.unit_apt || '' }}-</span>{{ section.street_number || '' }} {{ section.street_name || '' }}
                        </p>
                        <p v-if="section.city || section.ca_postal_code || section.ca_province.label || section.fr_region.label || section.us_state.label">
                            <template v-if="section.country?.value === 'ca'">
                                {{ section.city || '' }} ({{ section.ca_province.label || '' }}) {{ section.ca_postal_code || '' }}
                            </template>
                            <template v-else-if="section.country?.value === 'fr'">
                                {{ section.city || '' }}, {{ section.fr_region.label || '' }} {{ section.ca_postal_code || '' }}
                            </template>
                            <template v-else-if="section.country?.value === 'us'">
                                {{ section.city || '' }}, {{ section.us_state.label || '' }} {{ section.ca_postal_code || '' }}
                            </template>
                        </p>
                        <p v-if="section.country.label">
                            {{ section.country.label || '' }}
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-span-full md:col-span-2 md:col-start-3 lg:col-span-1">
                <div
                    :class="[
                        'flex items-center gap-3 text-lg',
                        classes.contentMarginBottomHalf[globals.styles.spacing?.value || ''],
                    ]"
                >
                    <AspectIcon class="size-5" name="store-1" />
                    {{ t('Opening Hours') }}
                </div>

                <ul
                    :class="[
                        'space-y-1.5',
                        classes.contentMarginBottomHalf[globals.styles.spacing?.value || ''],
                    ]"
                >
                    <li class="flex justify-between">
                        <span>
                            {{ t('Monday') }}
                        </span>
                        <span>
                            {{ section.monday[0].monday_opening }} – {{ section.monday[0].monday_closing }}
                        </span>
                    </li>
                    <li class="flex justify-between">
                        <span>
                            {{ t('Tuesday') }}
                        </span>
                        <span>
                            {{ section.tuesday[0].tuesday_opening }} – {{ section.tuesday[0].tuesday_closing }}
                        </span>
                    </li>
                    <li class="flex justify-between">
                        <span>
                            {{ t('Wednesday') }}
                        </span>
                        <span>
                            {{ section.wednesday[0].wednesday_opening }} – {{ section.wednesday[0].wednesday_closing }}
                        </span>
                    </li>
                    <li class="flex justify-between">
                        <span>
                            {{ t('Thursday') }}
                        </span>
                        <span>
                            {{ section.thursday[0].thursday_opening }} – {{ section.thursday[0].thursday_closing }}
                        </span>
                    </li>
                    <li class="flex justify-between">
                        <span>
                            {{ t('Friday') }}
                        </span>
                        <span>
                            {{ section.friday[0].friday_opening }} – {{ section.friday[0].friday_closing }}
                        </span>
                    </li>
                    <li class="flex justify-between">
                        <span>
                            {{ t('Saturday') }}
                        </span>
                        <span>
                            {{ section.saturday[0].saturday_opening }} – {{ section.saturday[0].saturday_closing }}
                        </span>
                    </li>
                    <li class="flex justify-between">
                        <span>
                            {{ t('Sunday') }}
                        </span>
                        <span>
                            {{ section.sunday[0].sunday_opening }} – {{ section.sunday[0].sunday_closing }}
                        </span>
                    </li>
                </ul>

                <div
                    v-if="section.notice || section.notice_title"
                    :class="[
                        'flex items-start',
                        `text-tenant-${section.notice_text_color?.value || 'black'}`,
                    ]"
                >
                    <AspectIcon class="mr-1.5 mt-1 size-5 shrink-0" name="information-circle" />
                    <div>
                        <h6 class="text-lg">
                            {{ section.notice_title || '' }}
                        </h6>
                        <div class="prose text-sm" v-html="section.notice || ''" />
                    </div>
                </div>
            </div>
        </article>
    </section>
</template>

<script lang="ts" setup>
    import { useSite } from '@/site/composables/use-site.ts';
    import { t } from '@aspect/shared/plugins/i18n.ts';

    import AspectIcon from '@aspect/shared/components/aspect-icon.vue';

    import SiteHeading from '@/site/components/site-heading.vue';

    const props = defineProps<{
        section: any;
    }>();

    const { classes, globals, getBackgroundOverlay } = useSite();

    const backgroundOverlay = computed(() => {
        return getBackgroundOverlay(props.section.background_overlay_color?.value, props.section.background_overlay_opacity);
    });

    const formattedPhoneNumber = computed(() => {
        if (!props.section.phone_number) {
            return '';
        }

        return props.section.phone_number.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2-$3');
    });

    const formattedPhoneNumberUrl = computed(() => {
        if (!props.section.phone_number) {
            return '';
        }

        const formattedNumber = props.section.phone_number.replace(/\D/g, '');
        const extensionBehavior = props.section.extension_behaviour?.value === 'wait' ? ';' : ',';
        const formattedExtension = props.section.has_extension && props.section.extension ? `${extensionBehavior}${props.section.extension}` : '';

        return `tel:${formattedNumber}${formattedExtension}`;
    });
</script>
