<!-- eslint-disable vue/no-v-html -->
<template>
    <section
        :id="section.section_id"
        :class="[
            'flex flex-col',
            classes.sectionPadding[globals.styles.spacing?.value || ''],
            classes.sectionGap[globals.styles.spacing?.value || ''],
            `bg-tenant-${section.background_color?.value || 'white'}`,
        ]"
        :style="[
            section.background_image && `background-image: linear-gradient(to right, rgba(${backgroundOverlay}), rgba(${backgroundOverlay})), url(${section.background_image.url});`,
        ]"
    >
        <SiteHeading
            v-if="section.title"
            :title="section.title"
            :title-color="section.title_text_color?.value"
            :subtitle="section.sub_title"
            :subtitle-color="section.subtitle_text_color?.value"
            :text-alignment="section.title_alignment?.value"
            :overlay-color="section.background_image ? section.background_overlay_color?.value : undefined"
        />

        <div
            :class="[
                'container grid items-start',
                classes.layoutGrid[section.column_quantity?.value || ''],
                classes.contentGapDouble[globals.styles.spacing?.value || ''],
            ]"
        >
            <article
                v-for="product in section.products || []"
                :key="product.sku"
                :class="[
                    'flex flex-col justify-between',
                    classes.contentGapHalf[globals.styles.spacing?.value || '']
                ]"
            >
                <figure
                    v-if="product.picture?.html"
                    :class="[
                        'grow-0 overflow-hidden border border-transparent',
                        // '*:size-full *:object-cover',
                        classes.rounding[globals.styles.rounding?.value || 'rounded'],
                        classes.imageAspectRatio[section.image_aspect_ratio?.value || ''],
                        classes.imagePosition[section.image_position?.value || ''],
                    ]"
                    v-html="product.picture.html || ''"
                />
                <figure
                    v-else
                    :class="[
                        'flex grow-0 items-center justify-center overflow-hidden border border-gray-200 bg-gray-150',
                        section.background_color?.value === 'white' && 'bg-tenant-light border-tenant-light-alt',
                        section.background_color?.value === 'light' && 'bg-tenant-white border-tenant-light-alt',
                        section.background_color?.value === 'light-alt' && 'bg-tenant-light border-tenant-white',
                        section.background_color?.value === 'dark-alt' && 'bg-tenant-dark border-tenant-black',
                        section.background_color?.value === 'dark' && 'bg-tenant-black border-tenant-dark-alt',
                        section.background_color?.value === 'black' && 'bg-tenant-dark border-tenant-dark-alt',
                        // Fuck me.
                        classes.rounding[globals.styles.rounding?.value || 'rounded'],
                        classes.imageAspectRatio[section.image_aspect_ratio?.value || ''],
                        classes.imagePosition[section.image_position?.value || ''],
                    ]"
                >
                    <AspectIcon
                        class="size-12 text-gray-400"
                        name="shipping-box-2"
                    />
                </figure>

                <div
                    :class="[
                        'flex grow flex-col justify-between',
                        classes.contentGap[globals.styles.spacing?.value || '']
                    ]"
                >
                    <div
                        :class="[
                            'flex flex-col',
                            classes.contentGapHalf[globals.styles.spacing?.value || ''],
                            classes.textAlignment[section.text_alignment?.value || ''],
                        ]"
                    >
                        <div
                            :class="[
                                'flex flex-col',
                                classes.textAlignment[section.text_alignment?.value || ''],
                            ]"
                        >
                            <SiteLabel
                                v-if="product.brand"
                                :text-color="section.brand_text_color?.value"
                            >
                                {{ product.brand || '' }}
                            </SiteLabel>
                            <SiteHeadingContent :text-color="section.name_text_color?.value">
                                {{ product.name || '' }}
                            </SiteHeadingContent>
                            <p
                                :class="[
                                    'text-gray-700',
                                    `text-tenant-${section.description_text_color?.value || 'black'}`,
                                ]"
                            >
                                {{ product.description || '' }}
                            </p>
                        </div>
                        <div
                            v-if="section.show_price"
                            :class="['flex items-center justify-end', classes.contentGapHalf[globals.styles.spacing?.value || '']]"
                        >
                            <strong
                                v-if="product.discount"
                                :class="[
                                    'text-xs',
                                    `text-tenant-white`,
                                    `bg-tenant-danger`,
                                    classes.rounding[globals.styles.rounding?.value || 'rounded'],
                                    classes.contentPaddingQuarter[globals.styles.spacing?.value || ''],
                                ]"
                            >
                                <template v-if="product.discountType === 'percentage'">
                                    - {{ formatPercent(product.discount / 100) }}
                                </template>
                                <template v-if="product.discountType === 'amount'">
                                    - {{ formatCurrency(product.discount) }}
                                </template>
                            </strong>
                            <del
                                v-if="product.discount"
                                :class="[
                                    'text-xs',
                                    `text-tenant-${section.price_text_color?.value}`,
                                ]"
                            >
                                {{ formatCurrency(product.price) }}
                            </del>
                            <samp
                                :class="[
                                    'font-sans text-2xl tabular-nums',
                                    `text-tenant-${section.price_text_color?.value}`,
                                ]"
                            >
                                {{ formatCurrency(product.discountedPrice) }}
                            </samp>
                        </div>
                    </div>
                </div>
            </article>
        </div>

        <div v-if="section.button" class="container flex justify-center">
            <SiteButton
                :disabled="section.button_disabled"
                :href="linkTypeHref(section)"
                :is-entry="section.button_link?.startsWith('/')"
                :target="section.button_target ? '_blank' : '_self'"
                :text="section.button_text"
                :tracking-id="section.button_tracking_id"
                :bg-color="section.button_background_color?.value || 'primary'"
                :text-color="section.button_text_color?.value || 'white'"
            />
        </div>
    </section>
</template>

<script lang="ts" setup>
    import { formatCurrency, formatPercent } from '@aspect/shared/utils/number.ts';
    import { useSite } from '@/site/composables/use-site.ts';

    import SiteButton from '@/site/components/site-button.vue';
    import SiteHeading from '@/site/components/site-heading.vue';
    import SiteLabel from '@/site/components/site-label.vue';
    import SiteHeadingContent from '@/site/components/site-heading-content.vue';
    import AspectIcon from '@aspect/shared/components/aspect-icon.vue';

    import type { SiteProductData } from '@aspect/shared/types/generated';

    const props = defineProps<{
        section: {
            products: SiteProductData[];
            [key: string]: any;
        };
    }>();

    const { classes, globals, getBackgroundOverlay } = useSite();

    const backgroundOverlay = computed(() => {
        return getBackgroundOverlay(props.section.background_overlay_color?.value, props.section.background_overlay_opacity);
    });

    const linkTypeHref = (section) => {
        // Hides link if button is disabled
        if (section.button_disabled) {
            return;
        }

        // Files
        if (section.button_link_type?.value === 'file') {
            return section.button_file?.url || '#';
        }

        // Email
        if (section.button_link_type?.value === 'email' && section.button_email_address) {
            return section.button_full_name ? `mailto:${section.button_full_name}<${section.button_email_address}>` : `mailto:${section.button_email_address}`;
        }

        // Phone
        if (section.button_link_type?.value === 'phone' && section.button_phone_number) {
            return `tel:${section.button_phone_number}`;
        }

        // Webpages
        if (section.button_link_type?.value === 'webpage') {
            return `${section.button_link || ''}${section.button_fragment_identifier ? `#${section.button_fragment_identifier}` : ''}`;
        }
    };
</script>
