<template>
    <section
        :id="section.section_id"
        :class="[
            classes.sectionPadding[globals.styles.spacing?.value || ''],
            section.background_image && 'bg-cover bg-no-repeat',
            section.background_image && classes.bgPosition[section.background_image_position?.value || ''],
            `bg-tenant-${section.background_color?.value || 'white'}`,
        ]"
        :style="[
            section.background_image && `background-image: linear-gradient(to right, rgba(${backgroundOverlay}), rgba(${backgroundOverlay})), url(${section.background_image.url});`,
        ]"
    >
        <SiteHeading
            v-if="section.title"
            :title="section.title"
            :title-color="section.title_text_color?.value"
            :subtitle="section.sub_title"
            :subtitle-color="section.subtitle_text_color?.value"
            :text-alignment="section.title_alignment?.value"
            :overlay-color="section.background_image ? section.background_overlay_color?.value : undefined"
        />

        <div
            :class="[
                'container grid items-stretch',
                classes.layoutGrid[section.column_quantity?.value || ''],
                classes.contentGapDouble[globals.styles.spacing?.value || ''],
            ]"
        >
            <component
                :is="partner.link ? 'a' : 'div'"
                v-for="partner in section.partners_gallery || []"
                :key="partner.id"
                :href="partner.link"
                class="flex items-center justify-center"
                :rel="partner.link && 'external'"
                :target="partner.link && '_blank'"
            >
                <figure
                    :class="[
                        'grow',
                        classes.opticalAdjustement[partner.optical_adjustments?.value || ''],
                        classes.rounding[globals.styles.rounding?.value || 'rounded'],
                    ]"
                >
                    <img
                        v-if="partner.logo"
                        :alt="partner.logo.alt"
                        class="size-full object-contain"
                        :height="partner.logo.height"
                        :src="partner.logo.url"
                        :width="partner.logo.width"
                    >
                    <span
                        v-else
                        :class="[
                            'text-center',
                            `text-tenant-${section.title_text_color?.value || 'black'}`,
                        ]"
                    >
                        {{ partner.logo_text || '' }}
                    </span>
                </figure>
            </component>
        </div>
    </section>
</template>

<script lang="ts" setup>
    import { useSite } from '@/site/composables/use-site.ts';

    import SiteHeading from '@/site/components/site-heading.vue';

    const props = defineProps<{
        section: any;
    }>();

    const { classes, globals, getBackgroundOverlay } = useSite();

    const backgroundOverlay = computed(() => {
        return getBackgroundOverlay(props.section.background_overlay_color?.value, props.section.background_overlay_opacity);
    });
</script>
