<!-- eslint-disable vue/no-v-html -->
<template>
    <section
        :id="section.section_id"
        :class="[
            classes.sectionPadding[globals.styles?.spacing?.value || 'normal'],
            section.background_image && 'bg-cover bg-no-repeat',
            section.background_image && classes.bgPosition[section.background_image_position?.value || 'center'],
            `bg-tenant-${section.background_color?.value || 'white'}`,
        ]"
        :style="[
            section.background_image && `background-image: linear-gradient(to right, rgba(${backgroundOverlay}), rgba(${backgroundOverlay})), url(${section.background_image.url});`,
        ]"
    >
        <SiteHeading
            v-if="section.title"
            :title="section.title"
            :title-color="section.title_text_color?.value"
            :subtitle="section.sub_title"
            :subtitle-color="section.subtitle_text_color?.value"
            :text-alignment="section.title_alignment?.value || 'center'"
            :class="[
                'container grid grid-cols-1',
                (section.title_alignment?.value || 'center') == 'left' && 'justify-content-start',
                (section.title_alignment?.value || 'center') == 'center' && 'justify-content-center',
                (section.title_alignment?.value || 'center') == 'right' && 'justify-content-end',
            ]"
            :overlay-color="section.background_image ? section.background_overlay_color?.value : undefined"
        />

        <div :class="['container', section.text_container && 'flex justify-center']">
            <div
                :class="[
                    'flex flex-col',
                    classes.contentGap[globals.styles?.spacing?.value || 'normal'],
                    (section.text_alignment?.value || 'left').endsWith('left') && 'items-start',
                    (section.text_alignment?.value || 'left').endsWith('center') && 'items-center',
                    (section.text_alignment?.value || 'left').endsWith('right') && 'items-end',
                ]"
            >
                <div
                    :class="[
                        'prose',
                        section.text_container ? 'max-w-prose' : 'max-w-full',
                        classes.textAlignment[section.text_alignment?.value || 'left'],
                        `text-tenant-${section.content_text_color?.value || 'black'}`,
                    ]"
                    v-html="section.content || ''"
                />

                <div v-if="section.button">
                    <SiteButton
                        :href="linkTypeHref(section)"
                        :is-entry="section.button_link?.startsWith('/')"
                        :target="section.button_target ? '_blank' : '_self'"
                        :tracking-id="section.button_tracking_id"
                        :bg-color="section.button_background_color?.value || 'primary'"
                        :text-color="section.button_text_color?.value || 'white'"
                    >
                        {{ section.button_text || '' }}
                    </SiteButton>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
    import { useSite } from '@/site/composables/use-site.ts';

    import SiteButton from '@/site/components/site-button.vue';
    import SiteHeading from '@/site/components/site-heading.vue';
    const props = defineProps<{
        section: any;
    }>();

    const { classes, globals, getBackgroundOverlay } = useSite();

    const backgroundOverlay = computed(() => {
        return getBackgroundOverlay(
            props.section.background_overlay_color?.value || 'darken',
            props.section.background_overlay_opacity || 50
        );
    });

    function linkTypeHref(section) {
        // Hides link if button is disabled
        if (section.button_disabled) {
            return;
        }

        // Files
        if (section.button_link_type?.value === 'file') {
            return section.button_file?.url || '#';
        }

        // Email
        if (section.button_link_type?.value === 'email' && section.button_email_address) {
            return section.button_full_name ? `mailto:${section.button_full_name}<${section.button_email_address}>` : `mailto:${section.button_email_address}`;
        }

        // Phone
        if (section.button_link_type?.value === 'phone' && section.button_phone_number) {
            return `tel:${section.button_phone_number}`;
        }

        // Webpages
        if (section.button_link_type?.value === 'webpage') {
            return `${section.button_link || ''}${section.button_fragment_identifier ? `#${section.button_fragment_identifier}` : ''}`;
        }

        // Default fallback
        return '#';
    }
</script>
