<template>
    <section
        :id="section.section_id"
        :class="[
            'bg-cover bg-no-repeat',
            section.background_image && 'bg-cover bg-no-repeat',
            section.background_image && classes.bgPosition[section.background_image_position?.value || ''],
            classes.sectionPadding[globals.styles.spacing?.value || ''],
            `bg-tenant-${section.background_color?.value || 'white'}`,
        ]"
        :style="[
            section.background_image && `background-image: linear-gradient(to right, rgba(${backgroundOverlay}), rgba(${backgroundOverlay})), url(${section.background_image.url});`,
        ]"
    >
        <SiteSectionHeading
            v-if="section.title"
            :title="section.title"
            :title-color="section.title_text_color?.value"
            :subtitle="section.sub_title"
            :subtitle-color="section.subtitle_text_color?.value"
            :text-alignment="section.title_alignment?.value"
            :overlay-color="section.background_image ? section.background_overlay_color?.value : undefined"
        />

        <div class="container">
            <iframe
                :allow="providerAllowances"
                :class="[
                    'mx-auto aspect-wide max-w-prose',
                    classes.rounding[globals.styles.rounding?.value || 'rounded'],
                ]"
                :src="formattedUrl"
                :title="section.description"
                allowfullscreen
                height="100%"
                width="100%"
            />
        </div>
    </section>
</template>

<script lang="ts" setup>
    import { useSite } from '@/site/composables/use-site.ts';

    import SiteSectionHeading from '@/site/components/site-heading.vue';

    const props = defineProps<{
        section: any;
    }>();

    const { classes, globals, getBackgroundOverlay } = useSite();

    const backgroundOverlay = computed(() => {
        return getBackgroundOverlay(props.section.background_overlay_color?.value, props.section.background_overlay_opacity);
    });

    const formattedUrl = computed(() => {
        if (props.section.provider?.value === 'youtube') {
            const youtube_id = props.section.url.split('v=')[1];
            return `https://www.youtube.com/embed/${youtube_id}`;
        }
        if (props.section.provider?.value === 'vimeo') {
            const vimeo_id = props.section.url.split('vimeo.com/')[1];
            return `https://player.vimeo.com/video/${vimeo_id}`;
        }
        return '';
    });

    const providerAllowances = computed(() => {
        if (props.section.provider === 'youtube') {
            return 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share';
        }
        if (props.section.provider === 'vimeo') {
            return 'autoplay; fullscreen; picture-in-picture';
        }
        return '';
    });
</script>
