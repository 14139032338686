<template>
    <component
        :is="headingTag"
        :class="[
            'text-2xl',
            classes.fontWeight[globals.fonts?.title_font_weight] || 'font-normal',
            classes.textTransform[globals.fonts?.title_text_transform] || 'normal-case',
            classes.letterSpacing[globals.fonts?.title_letter_spacing] || 'tracking-normal',
            `text-tenant-${textColor}`,
        ]"
    >
        <slot />
    </component>
</template>

<script setup lang="ts">
    import { useSite } from '@/site/composables/use-site.ts';

    withDefaults(defineProps<{
        headingTag?: string;
        textColor?: string;
    }>(), {
        headingTag: 'h3',
        textColor: 'black',
    });

    const { classes, globals } = useSite();
</script>
