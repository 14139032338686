<template>
    <div :class="['flex size-full items-stretch justify-between divide-x border-t border-gray-200', !showSlots && 'rounded-b', !onlyFilter && 'border-l']">
        <AspectButtonAttached
            :indicator="location === null"
            indicator-color="black"
            :disabled="disabled"
            :class="[
                'hidden h-full flex-1 md:flex',
                !showSlots && 'rounded-br',
                onlyFilter && !showSlots && 'rounded-bl',
            ]"
            @click="selectLocation(null)"
        >
            {{ t('All Courses') }}
        </AspectButtonAttached>
        <AspectButtonAttached
            v-for="locationItem in locations"
            :key="locationItem.id"
            :indicator="location === locationItem.id"
            indicator-color="black"
            :disabled="disabled"
            :class="[
                'hidden h-full flex-1 md:flex',
                !showSlots && 'rounded-br',
                onlyFilter && !showSlots && 'rounded-bl',
            ]"
            @click="selectLocation(locationItem.id)"
        >
            {{ locationItem.name }}
        </AspectButtonAttached>
        <AspectDropdown
            v-model:open="locationDropdown"
            :disabled="disabled"
            align="center"
            class="flex w-full md:hidden"
            :offset="0"
        >
            <template #trigger>
                <AspectButtonAttached :class="[!showSlots && 'rounded-b', 'size-full md:hidden']" :disabled="disabled">
                    {{ selectedLocationName }}
                    <AspectIcon v-if="!locationDropdown" name="arrow-down-3" class="size-4" />
                    <AspectIcon v-else name="arrow-up-3" class="size-4" />
                </AspectButtonAttached>
            </template>
            <template #content>
                <div class=" flex w-full flex-col gap-2">
                    <AspectDropdownCheckboxItem :checked="location === null" :disabled="disabled" @click="selectLocation(null)">
                        {{ t('All Courses') }}
                    </AspectDropdownCheckboxItem>
                    <AspectDropdownCheckboxItem
                        v-for="locationItem in locations"
                        :key="locationItem.id"
                        :checked="location === locationItem.id"
                        class="flex flex-col items-start gap-2"
                        @click="selectLocation(locationItem.id)"
                    >
                        <AspectData class="truncate text-xs">
                            {{ locationItem.name }}
                        </AspectData>
                    </AspectDropdownCheckboxItem>
                </div>
            </template>
        </AspectDropdown>
    </div>
</template>

<script setup lang="ts">
    import { t } from '@aspect/shared/plugins/i18n';
    import { computed, ref } from 'vue';

    import AspectButtonAttached from '@aspect/shared/components/aspect-button-attached.vue';
    import AspectDropdown from '@aspect/shared/components/aspect-dropdown.vue';
    import AspectDropdownCheckboxItem from '@aspect/shared/components/aspect-dropdown-checkbox-item.vue';

    import type { LocationData } from '@aspect/shared/types/generated';
    import AspectIcon from '@aspect/shared/components/aspect-icon.vue';

    const props = defineProps<{
        locations: LocationData[];
        disabled: boolean;
        showSlots: boolean;
        onlyFilter: boolean;
    }>();

    const location = defineModel<string | null>({ required: true });

    const locationDropdown = ref(false);

    const selectedLocationName = computed(() => {
        if (location.value === null) {
            return t('All Courses');
        }

        return props.locations.find((locationItem) => locationItem.id === location.value)?.name;
    });

    const selectLocation = (locationId: string | null) => {
        location.value = locationId;
        locationDropdown.value = false;
    };
</script>

