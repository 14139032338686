<!-- eslint-disable vue/no-v-html -->
<template>
    <section
        :id="section.section_id"
        :class="[
            classes.sectionPadding[globals.styles.spacing?.value || ''],
            section.background_image && 'bg-cover bg-no-repeat',
            section.background_image && classes.bgPosition[section.background_image_position?.value || ''],
            `bg-tenant-${section.background_color?.value || 'white'}`,
        ]"
        :style="[
            section.background_image && `background-image: linear-gradient(to right, rgba(${backgroundOverlay}), rgba(${backgroundOverlay})), url(${section.background_image.url});`,
        ]"
    >
        <SiteHeading
            v-if="section.title"
            :title="section.title"
            :title-color="section.title_text_color?.value"
            :subtitle="section.sub_title"
            :subtitle-color="section.subtitle_text_color?.value"
            :text-alignment="section.title_alignment?.value"
            :overlay-color="section.background_image ? section.background_overlay_color?.value : undefined"
        />

        <div
            :class="[
                'container grid items-stretch',
                classes.layoutGrid[section.column_quantity?.value || ''],
                classes.contentGap[globals.styles.spacing?.value || ''],
            ]"
        >
            <figure
                v-for="(item, index) in section.image_gallery"
                :key="item.id"
                :class="[
                    classes.rounding[globals.styles.rounding?.value || 'rounded'],
                ]"
                class="cursor-zoom-in"
                @click="onClick(index)"
            >
                <img
                    v-if="item.image"
                    :alt="item.image?.alt || ''"
                    :class="[
                        classes.rounding[globals.styles.rounding?.value || 'rounded'],
                        classes.imagePosition[item.image_position?.value || ''],
                        classes.imageAspectRatio[section.images_aspect_ratio?.value || ''],
                    ]"
                    :height="item.image?.height || ''"
                    :src="item.image?.url || ''"
                    :width="item.image?.width || ''"
                >
            </figure>
        </div>

        <VueEasyLightbox
            :imgs="imgsRef"
            :index="indexRef"
            :mask-closable="true"
            :move-disabled="true"
            :pinch-disabled="true"
            :rotate-disabled="true"
            :visible="visibleRef"
            :zoom-disabled="true"
            @hide="onHide"
        />
    </section>
</template>

<script lang="ts" setup>
    import '@/site/css/vendor/vue-easy-lightbox.css';
    import VueEasyLightbox, { useEasyLightbox } from 'vue-easy-lightbox';

    import { useSite } from '@/site/composables/use-site.ts';

    import SiteHeading from '@/site/components/site-heading.vue';

    const props = defineProps<{
        section: any;
    }>();

    const { classes, globals, getBackgroundOverlay } = useSite();

    const backgroundOverlay = computed(() => {
        return getBackgroundOverlay(props.section.background_overlay_color?.value, props.section.background_overlay_opacity);
    });

    const { show, onHide, changeIndex, visibleRef, indexRef, imgsRef } = useEasyLightbox({
        imgs: props.section.image_gallery.map((item: any) => item.image?.url || ''),
        initIndex: 0,
    });

    function onClick(index: number) {
        changeIndex(index);
        show();
    }
</script>
