<!-- eslint-disable vue/no-v-html -->
<template>
    <section
        :id="section.section_id"
        :class="[
            classes.sectionPadding[globals.styles?.spacing?.value || 'normal'],
            section.background_image && 'bg-cover bg-no-repeat',
            section.background_image && classes.bgPosition[section.background_image_position?.value || 'center'],
            `bg-tenant-${section.background_color?.value || 'white'}`,
        ]"
        :style="[
            section.background_image && `background-image: linear-gradient(to right, rgba(${backgroundOverlay}), rgba(${backgroundOverlay})), url(${section.background_image.url});`,
        ]"
    >
        <SiteHeading
            v-if="section.title"
            :title="section.title"
            :title-color="section.title_text_color?.value"
            :subtitle="section.sub_title"
            :subtitle-color="section.subtitle_text_color?.value"
            :text-alignment="section.title_alignment?.value || 'center'"
            :overlay-color="section.background_image ? section.background_overlay_color?.value : undefined"
        />
        <div
            :class="[
                'container grid items-stretch',
                classes.layoutGrid[section.column_quantity?.value || '3'],
                classes.contentGapDouble[globals.styles?.spacing?.value || 'normal'],
            ]"
        >
            <article
                v-for="card in section.cards || []"
                :key="card.id"
                :class="[
                    'flex flex-col',
                    classes.contentGapHalf[globals.styles?.spacing?.value || 'normal']
                ]"
            >
                <figure
                    v-if="card.image"
                    :class="[
                        classes.rounding[globals.styles?.rounding?.value || 'rounded'],
                    ]"
                >
                    <img
                        :alt="card.image?.alt || ''"
                        :class="[
                            classes.imageAspectRatio[card.image_aspect_ratio?.value || '16:9'],
                            classes.imagePosition[card.image_position?.value || 'center'],
                            classes.rounding[globals.styles?.rounding?.value || 'rounded'],
                        ]"
                        :height="card.image?.height || ''"
                        :src="card.image?.url || ''"
                        :width="card.image?.width || ''"
                    >
                </figure>

                <div
                    :class="[
                        'flex flex-1 flex-col justify-between',
                        classes.contentGap[globals.styles?.spacing?.value || 'normal']
                    ]"
                >
                    <div
                        :class="[
                            'flex flex-col',
                            classes.textAlignment[card.text_alignment?.value || 'left'],
                        ]"
                    >
                        <SiteLabel
                            v-if="card.label"
                            :text-color="card.label_text_color?.value"
                            :class="classes.textAlignment[card.text_alignment?.value || 'left']"
                        >
                            {{ card.label || '' }}
                        </SiteLabel>
                        <SiteHeadingContent
                            heading-tag="h4"
                            :text-color="card.title_text_color?.value"
                        >
                            {{ card.title || '' }}
                        </SiteHeadingContent>
                        <div
                            :class="[
                                'prose max-w-full',
                                `text-tenant-${card.content_text_color?.value || 'black'}`,
                            ]"
                            v-html="card.content || ''"
                        />
                    </div>

                    <div v-if="card.button" class="flex">
                        <SiteButton
                            :disabled="card.button_disabled"
                            :href="linkTypeHref(card)"
                            :is-entry="card.button_link?.startsWith('/')"
                            :target="card.button_target ? '_blank' : '_self'"
                            :text="card.button_text || ''"
                            :tracking-id="card.button_tracking_id"
                            class="grow"
                            :bg-color="card.button_background_color?.value || 'primary'"
                            :text-color="card.button_text_color?.value || 'white'"
                        />
                    </div>
                </div>
            </article>
        </div>
    </section>
</template>

<script lang="ts" setup>
    import { useSite } from '@/site/composables/use-site.ts';

    import SiteButton from '@/site/components/site-button.vue';
    import SiteHeading from '@/site/components/site-heading.vue';
    import SiteHeadingContent from '@/site/components/site-heading-content.vue';
    import SiteLabel from '@/site/components/site-label.vue';

    const props = defineProps<{
        section: any;
    }>();

    const { classes, globals, getBackgroundOverlay } = useSite();

    const backgroundOverlay = computed(() => {
        return getBackgroundOverlay(
            props.section.background_overlay_color?.value || 'darken',
            props.section.background_overlay_opacity || 50
        );
    });

    const linkTypeHref = (card) => {
        // Hides link if button is disabled
        if (card.button_disabled) {
            return;
        }

        // Files
        if (card.button_link_type?.value === 'file') {
            return card.button_file?.url || '#';
        }

        // Email
        if (card.button_link_type?.value === 'email' && card.button_email_address) {
            return card.button_full_name ? `mailto:${card.button_full_name}<${card.button_email_address}>` : `mailto:${card.button_email_address}`;
        }

        // Phone
        if (card.button_link_type?.value === 'phone' && card.button_phone_number) {
            return `tel:${card.button_phone_number}`;
        }

        // Webpages
        if (card.button_link_type?.value === 'webpage') {
            return `${card.button_link || ''}${card.button_fragment_identifier ? `#${card.button_fragment_identifier}` : ''}`;
        }

        // Default fallback
        return '#';
    };
</script>
