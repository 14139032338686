<template>
    <label
        :class="[
            'font-label text-sm',
            classes.fontWeight[globals.fonts?.label_font_weight] || 'font-medium',
            classes.letterSpacing[globals.fonts?.label_letter_spacing] || 'tracking-wide',
            classes.textTransform[globals.fonts?.label_text_transform] || 'uppercase',
            `text-tenant-${textColor}`,
        ]"
        :for="htmlFor"
    >
        <slot />
    </label>
</template>

<script setup lang="ts">
    import { useSite } from '@/site/composables/use-site.ts';

    withDefaults(defineProps<{
        textColor?: string;
        htmlFor?: string;
    }>(), {
        textColor: 'black',
        htmlFor: '',
    });

    const { classes, globals } = useSite();
</script>