<!-- eslint-disable vue/no-v-html -->
<template>
    <section
        :id="section.section_id"
        :class="[
            'flex flex-col justify-center bg-cover bg-no-repeat',
            classes.bgPosition[section.background_image_position?.value || ''],
            classes.sectionPadding[globals.styles.spacing?.value || ''],
            `bg-tenant-${section.background_color?.value || 'white'}`,
        ]"
        :style="[
            section.background_image && `background-image: linear-gradient(to right, rgba(${backgroundOverlay}), rgba(${backgroundOverlay})), url(${section.background_image.url});`,
        ]"
    >
        <SiteHeading
            :title="section.title"
            :title-color="section.title_text_color?.value"
            :subtitle="section.sub_title"
            :subtitle-color="section.subtitle_text_color?.value"
            :text-alignment="section.title_alignment?.value"
            :overlay-color="section.background_image ? section.background_overlay_color?.value : undefined"
        />

        <div
            :class="[
                'container grid grid-cols-1',
                classes.contentGap[globals.styles.spacing?.value || '']
            ]"
        >
            <div
                v-if="section.ctas.length"
                :class="[
                    'flex items-center',
                    classes.contentGapHalf[globals.styles.spacing?.value || ''],
                    ctaAlignement,
                ]"
            >
                <SiteButton
                    v-for="(cta, index) in section.ctas"
                    :key="index"
                    :href="linkTypeHref(cta)"
                    :is-entry="cta.button_link?.startsWith('/')"
                    :target="cta.button_target ? '_blank' : '_self'"
                    :text="cta.button_text"
                    :tracking-id="cta.button_tracking_id"
                    :bg-color="cta.button_background_color?.value || 'primary'"
                    :text-color="cta.button_text_color?.value || 'white'"
                />
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
    import { useSite } from '@/site/composables/use-site.ts';

    import SiteButton from '@/site/components/site-button.vue';
    import SiteHeading from '@/site/components/site-heading.vue';

    const props = defineProps<{
        section: any;
    }>();

    const { classes, globals, getBackgroundOverlay } = useSite();

    const backgroundOverlay = computed(() => {
        return getBackgroundOverlay(props.section.background_overlay_color?.value, props.section.background_overlay_opacity);
    });

    const ctaAlignement = computed(() => {
        return {
            left: 'justify-start',
            center: 'justify-center',
            right: 'justify-end',
        }[props.section.title_alignment?.value];
    });

    const linkTypeHref = (cta) => {
        // Hides link if button is disabled
        if (cta.button_disabled) {
            return;
        }

        // Files
        if (cta.button_link_type?.value === 'file') {
            return cta.button_file?.url || '#';
        }

        // Email
        if (cta.button_link_type?.value === 'email' && cta.button_email_address) {
            return cta.button_full_name ? `mailto:${cta.button_full_name}<${cta.button_email_address}>` : `mailto:${cta.button_email_address}`;
        }

        // Phone
        if (cta.button_link_type?.value === 'phone' && cta.button_phone_number) {
            return `tel:${cta.button_phone_number}`;
        }

        // Webpages
        if (cta.button_link_type?.value === 'webpage') {
            return `${cta.button_link || ''}${cta.button_fragment_identifier ? `#${cta.button_fragment_identifier}` : ''}`;
        }
    };
</script>
