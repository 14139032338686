<!-- eslint-disable vue/no-v-html -->
<template>
    <section
        :id="section.section_id"
        :class="[
            'flex flex-col justify-center text-opacity-95',
            classes.sectionPadding[globals.styles.spacing?.value || ''],
            section.notice_theme.value === 'info' && 'bg-tenant-primary',
            section.notice_theme.value === 'warning' && 'bg-tenant-warning',
            section.notice_theme.value === 'danger' && 'bg-tenant-danger',
        ]"
    >
        <div
            :class="[
                'container grid grid-cols-1 lg:grid-cols-4',
                classes.contentGap[globals.styles.spacing?.value || ''],
            ]"
        >
            <div class="flex justify-center lg:justify-end">
                <AspectIcon class="size-24 text-white text-opacity-95" :name="iconName" />
            </div>

            <div
                :class="[
                    'text-last-center lg:text-last-left col-span-3 flex flex-col text-justify lg:text-justify',
                    classes.contentGapHalf[globals.styles.spacing?.value || ''],
                ]"
            >
                <h3 class="text-3xl text-white">
                    {{ section.notice_title || '' }}
                </h3>
                <div class="text-white text-opacity-95 lg:text-xl" v-html="section.notice_description || ''" />
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
    import { useSite } from '@/site/composables/use-site.ts';

    import AspectIcon from '@aspect/shared/components/aspect-icon.vue';

    const props = defineProps<{
        section: any;
    }>();

    const { classes, globals } = useSite();

    const iconName = computed(() => {
        return {
            info: 'information-circle',
            warning: 'warning-diamond',
            danger: 'warning-triangle',
        }[props.section.notice_theme?.value];
    });
</script>
